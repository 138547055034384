<template>
  <div
    class="flow-container"
    :class="{
      'show-budget': shouldShowBudgetData,
      'is-lead-plan': isLeadPlan,
    }"
  >
    <div class="flow-dates-row p-d-flex" :style="{width: containerWidth + '%'}">
      <div class="title"></div>
      <div
        v-for="(month, index) in currentFlowDisplayMonths"
        :key="month.index"
        class="cell"
        :style="{width: (month.numDays * dayWidth).toString() + '%'}"
      >
        <div class="month-year">
          <span class="month">{{ month.name }}</span>
          <span class="year" v-if="index == 0 || month.month == 0">{{
            month.year
          }}</span>
        </div>
        <div class="dates p-d-flex">
          <div
            v-for="week in month.weeks"
            :key="week.index"
            class="date"
            :class="{'starts-mid-week': week.startsMidWeek}"
            :style="{
              width:
                Number((week.numDays / month.numDays) * 100).toString() + '%',
            }"
          >
            {{ week.firstDay }}
          </div>
        </div>
      </div>
      <div class="channel-meta-header-container">
        <!-- Media section hidden for MVP -->
        <!-- <div class="media-column">
          <h3>Media</h3>
          <div class="media-sub-column">Imp.</div>
          <div class="media-sub-column">
            {{ $store.getters.currentPlan.ratingPointsType }}
          </div>
        </div> -->
        <div class="expenses-column" v-if="shouldShowBudgetData">
          <h3>Expenses</h3>
          <div class="expenses-sub-column">Est</div>
          <div class="expenses-sub-column">Act</div>
          <div class="expenses-sub-column">Var</div>
        </div>
      </div>
    </div>
    <!-- Separate accordion for subscriptions so it is always above rows with tactic data  -->
    <Accordion
      :multiple="true"
      :activeIndex="subscriptionAccordionActiveIndicies"
      class="flow-accordion"
      :style="{width: containerWidth + '%'}"
      @tab-open="onRowOpen($event, 'subscriptions')"
      @tab-close="onRowClose($event, 'subscriptions')"
    >
      <AccordionTab v-if="selectedSubscriptionsWithEvents.length">
        <template #header>
          <h2>Calendars of Interest</h2>
        </template>
        <template #default>
          <template v-for="subscription in selectedSubscriptionsWithEvents">
            <div class="flow-events-row p-d-flex" :key="subscription.id.intID">
              <div class="title">
                <h3>{{ subscription.name }}</h3>
              </div>
              <div class="events-container">
                <CalendarFlowRow
                  :tactics="getFilteredEventsForSubscription(subscription)"
                  :dayWidth="dayWidth"
                  :millisecondsPerDay="millisecondsPerDay"
                  :dateRange="dateRange"
                  :isSubscription="true"
                  @showTactic="showTactic"
                />
              </div>
            </div>
          </template>
        </template>
      </AccordionTab>
    </Accordion>
    <Accordion
      :multiple="true"
      :activeIndex="initiativesAccordionActiveIndicies"
      class="flow-accordion"
      :style="{width: containerWidth + '%'}"
      @tab-open="onRowOpen($event, 'initiatives')"
      @tab-close="onRowClose($event, 'initiatives')"
    >
      <AccordionTab 
        key="initiatives" 
        v-show="categorizedInitiativesLength > 0"
      >
        <template #header>
          <h2>Initiatives</h2>
        </template>
        <template #default>
          <template 
            v-for="typeData in categorizedInitiatives" 
            >
            <div 
              class="flow-events-row p-d-flex" 
              v-if="doesTypeHaveTacticsInDateRange(typeData, dateRange)"
              :key="typeData.key"
              >
              <div class="title">
                <h3>{{ typeData.name }}</h3>
              </div>
              <div class="events-container">
                <CalendarFlowRow
                  :tactics="typeData.tactics"
                  :dayWidth="dayWidth"
                  :millisecondsPerDay="millisecondsPerDay"
                  :dateRange="dateRange"
                  @showTactic="showInitiative"
                  @showContextMenu="showContextMenu"
                  @showTacticSummaryOverlay="showTacticSummaryOverlay"
                  @hideTacticSummaryOverlay="hideTacticSummaryOverlay"
                />
              </div>
            </div>
          </template>
        </template>
      </AccordionTab>
    </Accordion>
    <Accordion
      :multiple="true"
      :activeIndex="activeAccordionIndicies"
      :key="'channelsAccordionKey-' + activeAccordionIndicies.length.toString()"
      class="flow-accordion"
      :style="{width: containerWidth + '%'}"
      @tab-open="onRowOpen($event, 'channels')"
      @tab-close="onRowClose($event, 'channels')"
    >
        <AccordionTab
          v-for="channelData in allChannels"
          :key="channelData.channel.id.intID"
          v-show="$store.getters.selectedChannels.filter((filterChannel)=>filterChannel.id.intID === channelData.channel.id.intID).length && channelData.tactics.length > 0"
        >
          <template #header>
            <h2 :title="channelData.channel.name">
              <template v-if="isLeadPlan && channelData.channel.isNested">
                <span
                  class="name channel-name"
                  :class="{
                    'is-nested': channelData.channel.isNested,
                    'is-lead': channelData.channel.isLead,
                  }"
                  >{{
                    channelData.channel.abbreviatedPlanName +
                    ' > ' +
                    channelData.channel.name
                  }}</span
                >
              </template>
              <template v-else>
                <span
                  class="name channel-name"
                  :class="{
                    'is-nested': channelData.channel.isNested,
                    'is-lead': channelData.channel.isLead,
                  }"
                  >{{ channelData.channel.name }}</span
                >
              </template>
            </h2>
            <div class="channel-meta-header-container">
              <!-- Media section hidden for MVP -->
              <!-- <div class="media-column"></div> -->
              <div class="expenses-column" v-if="shouldShowBudgetData"></div>
            </div>
          </template>
          <template #default>
            <div class="type-rows-container">
              <template v-for="(type) in channelData.types">
               <template v-if="$store.getters.selectedTacticTypes.filter((checkType) => checkType.id.intID === type.id.intID).length > 0">
                <div class="flow-events-row p-d-flex" :key="type.id.intID">
                  <div class="title" :title="type.name">
                    <h3>
                      {{ type.name }}
                    </h3>
                  </div>
                  <div class="events-container">
                    <CalendarFlowRow
                      :tactics="channelData.tactics.filter((tactic)=>tactic.tacticTypeId.intID === type.id.intID)"
                      :dayWidth="dayWidth"
                      :millisecondsPerDay="millisecondsPerDay"
                      :dateRange="dateRange"
                      :isHiddenFromNestedPlans="
                        type.shouldHideTacticsFromNestedPlans
                      "
                      :tacticBackgroundColor="channelData.channel.uiColor"
                      @showTactic="showTactic"
                      @showContextMenu="showContextMenu"
                      @showTacticSummaryOverlay="showTacticSummaryOverlay"
                      @hideTacticSummaryOverlay="hideTacticSummaryOverlay"
                    />
                  </div>
                  <div class="type-meta-body-container">
                    <!-- Media section hidden for MVP -->
                    <!-- <div class="media-column">
                    <template v-for="media in getMediaForType(type)">
                      <div
                        :key="media.index + '-estimate'"
                        class="media-sub-column"
                      >
                        {{ media.impressions.toLocaleString() }}
                      </div>
                      <div
                        :key="media.index + '-actual'"
                        class="media-sub-column"
                      >
                        {{ media.ratingPoints.toLocaleString() | currency }}
                      </div>
                    </template>
                  </div> -->
                    <div class="expenses-column" v-if="shouldShowBudgetData">
                      <template v-for="expense in getExpensesForType(type)">
                        <div
                          :key="expense.index + '-estimate'"
                          class="expenses-sub-column"
                        >
                          {{ expense.estimate | currency }}
                        </div>
                        <div
                          :key="expense.index + '-actual'"
                          class="expenses-sub-column"
                        >
                          {{ expense.actual | currency }}
                        </div>
                        <div
                          :key="expense.index + '-variant'"
                          class="expenses-sub-column"
                        >
                          {{ expense.variant | currency }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
               </template>
              </template>
              <!-- </template> -->
            </div>
            <div class="channel-meta-body-container">
              <!-- Media section hidden for MVP -->
              <!-- <div class="media-column"></div> -->
              <div class="expenses-column" v-if="shouldShowBudgetData">
                <!-- Placeholder for type expenses content -->
              </div>
            </div>
          </template>
        </AccordionTab>
      
      <!-- Media section hidden for MVP -->
      <!-- <AccordionTab :active="true" class="media-accordion-tab">
        <template #header>
          <h2>Media</h2>
        </template>
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h3>Impressions</h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            >
              <template
                v-for="monthlyMedia in getMonthlyMediaForPlan(month.firstDate)"
              >
                {{ monthlyMedia.impressions.toLocaleString() }}
              </template>
            </div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h3>{{ $store.getters.currentPlan.ratingPointsType }}</h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            >
              <template
                v-for="monthlyMedia in getMonthlyMediaForPlan(month.firstDate)"
              >
                {{ monthlyMedia.ratingPoints.toLocaleString() }}
              </template>
            </div>
          </div>
        </div>
      </AccordionTab> -->
    </Accordion>

    <div
      class="expenses-budget-row"
      :style="{width: containerWidth + '%'}"
      v-if="shouldShowBudgetData"
    >
      <div class="expense-rows-container">
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h2>Monthly Totals</h2>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            ></div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h3>Estimate</h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            >
              <template
                v-for="monthlyExpense in getMonthlyExpensesForPlan(
                  month.firstDate
                )"
              >
                {{ monthlyExpense.estimate | currency }}
              </template>
            </div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h3>Actual</h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            >
              <template
                v-for="monthlyExpense in getMonthlyExpensesForPlan(
                  month.firstDate
                )"
              >
                {{ monthlyExpense.actual | currency }}
              </template>
            </div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex">
          <div class="title">
            <h3>Variant</h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            >
              <template
                v-for="monthlyExpense in getMonthlyExpensesForPlan(
                  month.firstDate
                )"
              >
                {{ monthlyExpense.variant | currency }}
              </template>
            </div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex budget-flow-row">
          <div class="title">
            <h2><span class="invisible-text">Budget vs Actual</span></h2>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            ></div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex budget-flow-row">
          <div class="title">
            <h3><span class="invisible-text">Budget</span></h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            ></div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex budget-flow-row">
          <div class="title">
            <h3><span class="invisible-text">Actual</span></h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            ></div>
          </div>
        </div>
        <div class="flow-events-row p-d-flex budget-flow-row">
          <div class="title">
            <h3><span class="invisible-text">Remaining</span></h3>
          </div>
          <div class="events-container">
            <div
              v-for="month in currentFlowDisplayMonths"
              :key="month.index"
              :style="{width: (month.numDays * dayWidth).toString() + '%'}"
              class="total-row-value"
            ></div>
          </div>
        </div>
      </div>
      <div class="expenses-column" v-if="shouldShowBudgetData">
        <div class="expenses-section">
          <h2>Total Expenses</h2>
          <template v-for="expenses in getTotalExpensesForPlan()">
            <div :key="expenses.index">
              <h3>
                Total Estimate
                <span>{{ expenses.estimate | currency }}</span>
              </h3>
              <h3>
                Total Actual <span>{{ expenses.actual | currency }}</span>
              </h3>
              <h3>
                Total Variant <span>{{ expenses.variant | currency }}</span>
              </h3>
            </div>
          </template>
        </div>
        <div class="budget-section" v-if="shouldShowBudgetVsActual">
          <h2>Budget vs Actual</h2>
          <div>
            <h3>
              Total Budget
              <span>{{
                $store.getters.currentPlan.totalBudget | currency
              }}</span>
            </h3>
            <!-- Estimated totals hidden for MVP -->
            <!-- <h3>
                  Estimated: <span>{{ totalBudgetEstimated | currency }} ({{
                    totalBudgetAssigned
                  }}% assigned)</span>
                </h3> -->
            <h3>
              Total Actual <span>{{ totalBudgetActuals | currency }}</span>
            </h3>
            <h3>
              Total Remaining
              <span>{{ totalBudgetRemaining | currency }}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-container">
      <p class="copyright">Copyright &copy; 2024 Annum. All rights reserved. United States Patent Number 12,079,779.</p>
    </div>

    <ContextMenu ref="menu" :model="contextMenuItems" appendTo="body" />

    <OverlayPanel ref="tacticSummaryOverlay" class="tactic-summary-overlay" appendTo="body">
      <TacticSummary :tacticId="currentSummaryTacticId" />
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import {UserMostRecentView} from '@/models/User'
import Button from 'primevue/button'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Tactic from '@/models/Tactic'
import FlowDisplayMonth from '@/models/FlowDisplayMonth'
import Channel from '@/models/Channel'
import TacticType from '@/models/TacticType'
import CalendarFlowRow from '@/components/CalendarFlowRow.vue'
import TotalExpense from '@/models/TotalExpense'
import TotalMedia from '@/models/TotalMedia'
import Subscription from '@/models/Subscription'
import {rrulestr} from 'rrule'
import {
  isSameDay,
  differenceInHours,
  differenceInMinutes,
  addMinutes,
} from 'date-fns'
import ID from '@/models/ID'
import ContextMenuItem from '@/models/ContextMenuItem'
import ContextMenu from 'primevue/contextmenu'
import {PlanType} from '@/models/Plan'
import TacticSummary from '@/views/TacticSummary.vue'
import RelatedInitiativeMapping from '@/models/RelatedInitiativeMapping'
import AppStore from '@/AppStore'

Vue.component('Button', Button)
Vue.component('ContextMenu', ContextMenu)
Vue.component('Button', Button)
Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)

export default Vue.extend({
  name: 'CalendarFlow',
  components: {
    CalendarFlowRow,
    TacticSummary,
  },
  data: () => {
    return {
      millisecondsPerDay: (1000 * 60 * 60 * 24) as number,
      dayWidthTargetPx: 30 as number, //TODO: express this in rems
      subscriptionsUpdateItterator: 0 as number,
      tacticsUpdateIterator: 0 as number,
      rightClickedTacticId: {} as ID,
      rightClickedTacticIsInitiative: false as boolean,
      currentSummaryTacticId: {} as ID,
      currentSummaryTimeout: 0,
      isDisplayingSummaryTactic: false as boolean,
      channelsAccordionKey: 'channelsAccordion' as string,
    }
  },
  computed: {
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    isNestedPlan(): boolean {
      return this.$store.getters.currentPlan.parentId.intID !== 0 ? true : false
    },
    // dateRange(): Date[] {
    //   return this.$store.getters.currentDateRange
    // },
    dateRange(): Date[] {
      const adjustedDateRangeStart = new Date(
        this.$store.getters.currentDateRange[0]
      )
      adjustedDateRangeStart.setHours(0, 0, 0, 0)
      const adjustedDateRangeEnd = new Date(
        this.$store.getters.currentDateRange[1]
      )
      adjustedDateRangeEnd.setHours(23, 59, 59, 999)
      return [adjustedDateRangeStart, adjustedDateRangeEnd]
    },
    dayWidth(): number {
      if (!this.dateRange[1] || this.dateRange[0] == this.dateRange[1]) {
        return 100
      }
      const utc1 = Date.UTC(
        this.dateRange[0].getFullYear(),
        this.dateRange[0].getMonth(),
        this.dateRange[0].getDate()
      )
      const utc2 = Date.UTC(
        this.dateRange[1].getFullYear(),
        this.dateRange[1].getMonth(),
        this.dateRange[1].getDate()
      )
      // Calculate milliseconds between start and end dates and add one day so last day of range is displayed
      return 100 / ((utc2 - utc1 + this.millisecondsPerDay) / this.millisecondsPerDay)
    },
    containerWidth(): number {
      const computedContainerWidth =
        (((this.dayWidthTargetPx / this.dayWidth) * 100) / window.innerWidth) *
        100
      return Math.max(computedContainerWidth, 100)
    },
    currentFlowDisplayMonths(): FlowDisplayMonth[] {
      const returnArray = [] as FlowDisplayMonth[]
      if (!this.dateRange[0] || !this.dateRange[1]) return returnArray

      const tempDate = new Date(this.dateRange[0].toString())
      let numMonths =
        (this.dateRange[1].getFullYear() - this.dateRange[0].getFullYear()) * 12
      numMonths -= this.dateRange[0].getMonth()
      numMonths += this.dateRange[1].getMonth()

      for (let i = 0; i <= numMonths; i += 1) {
        if (i > 0) {
          tempDate.setMonth(tempDate.getMonth() + 1)
        }
        returnArray.push(
          new FlowDisplayMonth(
            i,
            tempDate.toLocaleString('default', {
              month: 'short',
            }),
            i == 0 ? this.dateRange[0].getDate() : 1,
            i == numMonths
              ? this.dateRange[1].getDate()
              : new Date(
                  tempDate.getFullYear(),
                  tempDate.getMonth() + 1,
                  0
                ).getDate(),
            tempDate.getMonth(),
            tempDate.getFullYear(),
            this.$store.getters.currentPlan.weekStartDay
          )
        )
      }
      return returnArray
    },
    categorizedInitiatives(): any[] {
      return this.$store.getters.selectedInitiativesInCategories
    },
    categorizedInitiativesLength(): number {
      let count = 0
      this.categorizedInitiatives.forEach((typeData)=>{
        count += typeData.tactics.length
      })
      return count
    },
    allChannels(): any[] {
      const returnChannels = [] as any[]
      this.$store.getters.currentPlan.channels.forEach((channel) => {
        let channelHasTactics = false
        const returnTypes = [] as TacticType[]
        const returnTactics = [] as Tactic[]
        let tacticsWithClonesForType = [] as Tactic[]
        channel.tacticTypes.forEach((type) => {
          let typeHasTactics = false

          // Filter out types that are deselected in filters
          if(this.$store.getters.selectedTacticTypes.filter((checkType) => checkType.id.intID === type.id.intID).length){
            // Get tactics along with clones for recurring tactics
            tacticsWithClonesForType = type.getTacticsWithClonesForDateRange(this.dateRange)

            tacticsWithClonesForType.forEach((tactic) => {
              const tempTactic = tactic

              //Check date, lead/nested plan, initiative, tag, and tactic flagging filters
              if (
                tempTactic.startDate <= this.dateRange[1] &&
                tempTactic.endDate >= this.dateRange[0] &&
                !this.isTacticInFilteredOutPlan(tempTactic) && 
                !this.isTacticRelatedToDeselectedInitiative(tempTactic) &&
                !this.isTacticRelatedToDeselectedTag(tempTactic) &&
                (channel.enableTacticFlagging == false || (channel.enableTacticFlagging == true && tempTactic.isFlagged == true) || (channel.enableTacticFlagging == true && type.name.toLowerCase() === this.$store.getters.monthlyFocusTypeName))
              ) {
                channelHasTactics = true
                typeHasTactics = true
                returnTactics.push(tempTactic)
              }
            })
            //Only add type if it has valid tactics and a type of the same name (but a different platform) doesn't already exist in returnTypes array
            if (
              typeHasTactics &&
              !returnTypes.find((checkType) => checkType.name == type.name) &&
              (type.name.toLowerCase() !== this.$store.getters.monthlyFocusTypeName || channel.useMonthlyFocusType || (channel.enableTacticFlagging == true && type.name.toLowerCase() === this.$store.getters.monthlyFocusTypeName) )
            ) {
              returnTypes.push({
                ...type,
                tactics: tacticsWithClonesForType
              })
            }
          }
        })
        if (channelHasTactics) {
          returnChannels.push({
            channel: channel,
            types: returnTypes,
            tactics: returnTactics,
          })
        }
        // else if (
        //   channel.useMonthlyFocusType == true
        // ) {
        //   returnChannels.push({
        //     channel: channel,
        //     types: returnTypes.filter(
        //       (type) =>
        //         type.name.toLowerCase() ==
        //         this.$store.getters.monthlyFocusTypeName
        //     ),
        //     tactics: returnTactics.filter(
        //       (tactic) =>
        //         tactic.tacticTypeName.toLowerCase() ==
        //         this.$store.getters.monthlyFocusTypeName
        //     ),
        //   })
        // }
      })

      this.tacticsUpdateIterator

      return returnChannels.sort((a, b)=>{
        if(a.channel.orderIndex === b.channel.orderIndex){
          return 0
        }
        return a.channel.orderIndex > b.channel.orderIndex ? 1 : -1
      })
    },
    // totalBudgetEstimated(): number {
    //   let totalEstimated = 0
    //   this.$store.getters.currentChannels.forEach((channel) => {
    //     channel.budgets?.forEach((budget) => {
    //       totalEstimated += budget.value
    //     })
    //   })
    //   return totalEstimated
    // },
    // totalBudgetAssigned(): number {
    //   return Math.round(
    //     (this.totalBudgetEstimated /
    //       this.$store.getters.currentPlan.totalBudget) *
    //       100
    //   )
    // },
    totalBudgetActuals(): number {
      let totalActual = 0
      this.$store.getters.currentChannels.forEach((channel) => {
        if (
          (this.isLeadPlan && channel.isLead) ||
          (!this.isLeadPlan && !channel.isLead)
        ) {
          totalActual += this.getActualsTotalForChannel(channel)
        }
      })
      return totalActual
    },
    totalBudgetRemaining(): number {
      return (
        this.$store.getters.currentPlan.totalBudget - this.totalBudgetActuals
      )
    },
    activeAccordionIndicies(): number[] {
      const inactiveRowsArray = this.getInactiveRowsLocalStorageArray()
      const returnArray = [] as number[]
      this.allChannels.forEach((channelData, index) => {
        if (
          !inactiveRowsArray ||
          !inactiveRowsArray?.includes(channelData.channel.id.intID.toString())
        ) {
          returnArray.push(index)
        }
      })
      return returnArray
    },
    initiativesAccordionActiveIndicies(): number[] {
      return this.getInactiveRowsLocalStorageArray()?.includes('initiatives')
        ? []
        : [0]
    },
    subscriptionAccordionActiveIndicies(): number[] {
      return this.getInactiveRowsLocalStorageArray()?.includes('subscriptions')
        ? []
        : [0]
    },
    selectedSubscriptions(): Subscription[] {
      return this.$store.getters.selectedSubscriptions
    },
    selectedSubscriptionsWithEvents(): Subscription[] {
      this.subscriptionsUpdateItterator
      const subscriptionsReturnArray = [] as Subscription[]
      this.$store.getters.selectedSubscriptions.forEach((subscription) => {
        const subscriptionCopy = {...subscription}

        // Add recurrence clones
        this.$store.dispatch('addRecurringEventClones', subscriptionCopy)
        
        subscriptionsReturnArray.push({
          ...subscriptionCopy,
          events: [...subscriptionCopy.events] as Tactic[],
        })
      })

      return subscriptionsReturnArray
    },
    shouldShowBudgetVsActual(): boolean {
      return (
        this.$store.getters.currentPlan.isBudgetEnabled &&
        this.$store.getters.currentUserRole.canViewBudget &&
        this.dateRange[0] <= this.$store.getters.currentPlan.startDate &&
        this.dateRange[1] >= this.$store.getters.currentPlan.endDate
      )
    },
    contextMenuItems(): ContextMenuItem[] {
      return [
        {
          label: `Copy ${this.rightClickedTacticIsInitiative ? 'Initiative' : 'Tactic'}`,
          icon: 'pi pi-fw pi-copy',
          command: this.openTacticDetailWithCopyId,
        },
      ]
    },
    flowInactiveRowsLocalStorageKey(): string {
      return (
        'flow-inactive-rows-' +
        this.$store.getters.currentPlan.id.intID.toString()
      )
    },
    shouldShowBudgetData(): boolean {
      return (
        this.$store.getters.currentPlan.isBudgetEnabled &&
        this.$store.getters.currentUserRole.canViewBudget &&
        (
          (!this.isLeadPlan && !this.isNestedPlan) ||
          (this.isNestedPlan && !this.$store.getters.isLeadPlanVisible) ||
          (this.isLeadPlan && !this.$store.getters.visibleNestedPlansIntIds?.length)
        )
      )
    },
  },
  watch: {
    '$store.getters.selectedSubscriptions': {
      deep: true,
      handler() {
        // Force update of selectedSubscriptionsWithEvents when subscriptions events have loaded
        this.subscriptionsUpdateItterator += 1

        // Trigger date update with exact same date to trigger render in CalendarFlowRows
        setTimeout(() => {
          this.$store.dispatch('updateCurrentDateRange', [
            this.$store.getters.currentDateRange[0],
            this.$store.getters.currentDateRange[1],
          ])
        }, 100)
      },
    },
    '$store.getters.currentDateRange': {
      deep: true,
      handler() {
        // TODO: forces an update so that additional recurring items show up in the newly exapanded flow view
        setTimeout(() => (this.tacticsUpdateIterator += 1), 100)
      },
    },
  },
  mounted: function(){
    // Set date range to non-month range
    this.$store.dispatch('updateCurrentDateRange', this.$store.getters.rangeViewsDateRange)

    // Force update with newest tactics
    this.$store.dispatch('refreshCurrentPlan')
  },
  methods: {
    doesTypeHaveTacticsInDateRange(type: TacticType, dateRange: Date[]): boolean {
      let returnValue = false
      type.tactics.some((tactic)=>{
        if(tactic.startDate <= dateRange[1] && tactic.endDate >= dateRange[0]){
          returnValue = true
          return true
        }
      })
      return returnValue
    },
    isTacticInFilteredOutPlan(tactic: Tactic): boolean {
      if (tactic.isLead && !this.$store.getters.isLeadPlanVisible) {
        return true
      }
      if (
        tactic.isNested &&
        !this.$store.getters.visibleNestedPlansIntIds?.find(
          (planIntId) => planIntId === tactic.planId.intID
        )
      ) {
        return true
      }
      return false
    },
    isTacticMultiMonth(tactic: Tactic): boolean {
      return (
        (this.$store.getters.currentDateRange[0] > tactic.startDate &&
          this.$store.getters.currentDateRange[0] < tactic.endDate) ||
        (this.$store.getters.currentDateRange[1] > tactic.startDate &&
          this.$store.getters.currentDateRange[1] < tactic.endDate)
      )
    },
    isTacticEntireMonth(tactic: Tactic): boolean {
      return (
        this.$store.getters.currentDateRange[0] <= tactic.startDate &&
        this.$store.getters.currentDateRange[1] >= tactic.endDate
      )
    },
    isTacticExactMonth(tactic: Tactic): boolean {
      return (
        this.dateRange[0].getFullYear() == tactic.startDate.getFullYear() &&
        this.dateRange[0].getMonth() == tactic.startDate.getMonth() &&
        this.dateRange[1].getFullYear() == tactic.endDate.getFullYear() &&
        this.dateRange[1].getMonth() == tactic.endDate.getMonth()
      )
    },
    isTacticLongerThanThreshold(
      tactic: Tactic,
      thresholdInDays: number
    ): boolean {
      return (
        Math.ceil(
          (Date.UTC(
            tactic.endDate.getFullYear(),
            tactic.endDate.getMonth(),
            tactic.endDate.getDate()
          ) -
            Date.UTC(
              tactic.startDate.getFullYear(),
              tactic.startDate.getMonth(),
              tactic.startDate.getDate()
            )) /
            (1000 * 60 * 60 * 24)
        ) >= thresholdInDays
      )
    },
    isTacticInPaidChannel(tactic: Tactic): boolean {
      return (
        this.$store.getters.paidChannelNames.indexOf(
          tactic.channelName.toLowerCase()
        ) > -1
      )
    },
    isTacticRelatedToDeselectedTag(tactic: Tactic): boolean {
      if(this.$store.getters.isTagFilterActive){
        if (tactic.tags.length == 0) {
          //Tag filters are selected and tactic has no tags assigned
          return true
        }
        // Tags have been selected, show any tactics that have any selected tags
        return tactic.tags.filter(
            (tag) =>
              this.$store.getters.selectedTags.filter(
                (selectedTag) => selectedTag.id.intID == tag.id.intID
              ).length > 0
          ).length
            ? false
            : true
      }
      return false
    },
    isTacticRelatedToDeselectedInitiative(tactic: Tactic): boolean {
      const relatedInitiatives = tactic.relatedInitiatives.map((mapping: RelatedInitiativeMapping) => mapping.initiativeId.intID)
      if (this.$store.getters.isInitiativeFilterActive && relatedInitiatives.filter((intID) => intID === 0).length) {
        //Initiative filters are selected and tactic has no initiatives assigned
        return true
      }
      if (
        this.$store.getters.isInitiativeFilterActive &&
        this.$store.getters.selectedInitiatives.length == 1
      ) {
        //Only one initiative has been selected, show any tactics that have selected initiative
        return relatedInitiatives.filter(
          (intID) =>
            this.$store.getters.selectedInitiatives.filter(
              (selectedInitiative) => selectedInitiative.id.intID == intID
            ).length > 0
        ).length
          ? false
          : true
      }
      //Multiple initiatives are selected, show tactics that have all of the selected initiatives
      return relatedInitiatives.filter(
        (intID) =>
          this.$store.getters.deselectedInitiatives.filter(
            (deselectedInitiative) => deselectedInitiative.id.intID == intID
          ).length > 0
      ).length
        ? true
        : false
    },
    isEventDuplicate(event: Tactic, subscription: Subscription): boolean {
      let returnValue = false
      const duplicateMappingsForTactic = subscription.duplicateEventsMap.filter(
        (duplicateMapping) => duplicateMapping.eventId == event.id.intID
      )
      // Only check for duplicates if there is a duplicate for the provided event
      if (duplicateMappingsForTactic.length) {
        duplicateMappingsForTactic.forEach((duplicateMapping) => {
          // Check if subscription with duplicate is visible
          const selectedSubscriptionWithDuplicate =
            this.$store.getters.selectedSubscriptions.find(
              (selectedSubscription) =>
                selectedSubscription.id.intID ==
                duplicateMapping.duplicateSubscriptionId
            )
          if (selectedSubscriptionWithDuplicate) {
            // Check if subscription with duplicate has a lower ID value than the provided subscription - event with lowest subscription ID will be shown
            if (
              duplicateMapping.duplicateSubscriptionId <
              duplicateMapping.subscriptionId
            ) {
              returnValue = true
            }
          }
        })
      }
      return returnValue
    },
    getFilteredEventsForSubscription(subscription: Subscription): Tactic[] {
      return subscription.events.filter((event) => {
        return !this.isEventDuplicate(event, subscription)
      })
    },
    showTactic(tactic: Tactic) {
      const thisTacticIntID = tactic.originalTacticID ? tactic.originalTacticID.intID : tactic.id.intID
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/tactic/${thisTacticIntID}`,
      })
    },
    showInitiative(initiative: Tactic) {
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/initiative/${initiative.id.intID}`,
      })
    },
    showContextMenu({e, tactic}) {
      this.rightClickedTacticId = tactic.id
      this.rightClickedTacticIsInitiative = tactic.isInitiative
      ;(this.$refs.menu as Vue & {show: (e) => boolean}).show(e)
    },
    getMediaForType(type: TacticType): TotalMedia[] {
      let returnImpressions = 0
      let returnRaitingPoints = 0
      type.tactics.forEach((tactic) => {
        if (
          tactic.endDate >= this.dateRange[0] &&
          tactic.endDate <= this.dateRange[1] &&
          ((this.isLeadPlan && tactic.isLead) ||
            (!this.isLeadPlan && !tactic.isLead))
        ) {
          returnImpressions += tactic.impressions
          returnRaitingPoints += tactic.ratingPoints
        }
      })
      return [new TotalMedia(0, returnImpressions, returnRaitingPoints)]
    },
    getExpensesForType(type: TacticType): TotalExpense[] {
      let returnEstimate = 0
      let returnActual = 0
      this.allChannels.forEach((channelData)=>{
        if(this.$store.getters.selectedChannels.filter((filterChannel)=> filterChannel.id.intID === channelData.channel.id.intID).length ){
          channelData.tactics.forEach((tactic)=>{
            if (
              tactic.tacticTypeId.intID === type.id.intID &&
              tactic.endDate >= this.dateRange[0] &&
              tactic.endDate <= this.dateRange[1] &&
              ((this.isLeadPlan && tactic.isLead) ||
                (!this.isLeadPlan && !tactic.isLead))
            ) {
              returnEstimate += tactic.estimatedCost
              returnActual += tactic.actualCost
            }
          })
        }
      })
      return [new TotalExpense(0, returnEstimate, returnActual)]
    },
    getPortionOfPlanBudgetForChannelBudget(channelTotal: number) {
      if (this.$store.getters.currentPlan.totalBudget == 0 || channelTotal == 0)
        return 0
      return Math.round(
        (channelTotal / this.$store.getters.currentPlan.totalBudget) * 100
      )
    },
    getActualsTotalForChannel(channel: Channel) {
      let returnActual = 0
      channel.tacticTypes.forEach((type) => {
        type.tactics.forEach((tactic) => {
          if (
            tactic.endDate > this.dateRange[0] &&
            tactic.endDate < this.dateRange[1] &&
            ((this.isLeadPlan && tactic.isLead) ||
              (!this.isLeadPlan && !tactic.isLead))
          ) {
            returnActual += tactic.actualCost
          }
        })
      })
      return returnActual
    },
    getMonthlyMediaForPlan(startDate: Date) {
      let returnImpressions = 0
      let returnRatingPoints = 0
      this.$store.getters.currentChannels.forEach((channel) => {
        channel.tacticTypes.forEach((type) => {
          type.tactics.forEach((tactic) => {
            if (
              tactic.endDate.getMonth() == startDate.getMonth() &&
              ((this.isLeadPlan && tactic.isLead) ||
                (!this.isLeadPlan && !tactic.isLead))
            ) {
              returnImpressions += tactic.impressions
              returnRatingPoints += tactic.ratingPoints
            }
          })
        })
      })
      return [new TotalMedia(0, returnImpressions, returnRatingPoints)]
    },
    getMonthlyExpensesForPlan(startDate: Date) {
      let returnEstimate = 0
      let returnActual = 0
      this.allChannels.forEach((channelData)=>{
        if(this.$store.getters.selectedChannels.filter((filterChannel)=> filterChannel.id.intID === channelData.channel.id.intID).length ){
          channelData.types?.forEach((type) => {
            if(this.$store.getters.selectedTacticTypes.map((type)=>type.id.intID).includes(type.id.intID)){
              type.tactics.forEach((tactic)=>{
                if (
                  tactic.endDate.getMonth() == startDate.getMonth() &&
                  ((this.isLeadPlan && tactic.isLead) ||
                    (!this.isLeadPlan && !tactic.isLead))
                ) {
                  returnEstimate += tactic.estimatedCost
                  returnActual += tactic.actualCost
                }
              })
            }
          })
        }
      })
      return [new TotalExpense(0, returnEstimate, returnActual)]
    },
    getTotalMediaForPlan() {
      let returnImpressions = 0
      let returnRatingPoints = 0
      this.$store.getters.currentChannels.forEach((channel) => {
        channel.tacticTypes.forEach((type) => {
          type.tactics.forEach((tactic) => {
            if (
              this.dateRange[0] &&
              this.dateRange[1] &&
              tactic.endDate.getMonth() >= this.dateRange[0].getMonth() &&
              tactic.endDate.getMonth() <= this.dateRange[1].getMonth() &&
              ((this.isLeadPlan && tactic.isLead) ||
                (!this.isLeadPlan && !tactic.isLead))
            ) {
              returnImpressions += tactic.impressions
              returnRatingPoints += tactic.ratingPoints
            }
          })
        })
      })
      return [new TotalMedia(0, returnImpressions, returnRatingPoints)]
    },
    getTotalExpensesForPlan() {
      let returnEstimate = 0
      let returnActual = 0
      this.allChannels.forEach((channelData)=>{
        if(this.$store.getters.selectedChannels.filter((filterChannel)=> filterChannel.id.intID === channelData.channel.id.intID).length ){
          channelData.types?.forEach((type) => {
            if(this.$store.getters.selectedTacticTypes.map((type)=>type.id.intID).includes(type.id.intID)){
              type.tactics.forEach((tactic)=>{
                if (
                  this.dateRange[0] &&
                  this.dateRange[1] &&
                  tactic.endDate.getMonth() >= this.dateRange[0].getMonth() &&
                  tactic.endDate.getMonth() <= this.dateRange[1].getMonth() &&
                  ((this.isLeadPlan && tactic.isLead) ||
                    (!this.isLeadPlan && !tactic.isLead))
                ) {
                  returnEstimate += tactic.estimatedCost
                  returnActual += tactic.actualCost
                }
              })
            }
          })
        }
      })

      return [new TotalExpense(0, returnEstimate, returnActual)]
    },
    openTacticDetailWithCopyId() {
      this.$router.push({
        path:
          `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/${this.rightClickedTacticIsInitiative ? 'initiative' : 'tactic'}/0?copyof=${this.rightClickedTacticId.intID}`
      })
    },
    onRowOpen(event, accordionName: string) {
      let rowId
      if (accordionName == 'subscriptions') {
        rowId = 'subscriptions'
      } else if (accordionName == 'initiatives') {
        rowId = Channel.CHANNEL_NAME_INITIATIVES.toLowerCase()
      } else {
        rowId = this.allChannels[event.index].channel.id.intID.toString()
      }

      const inactiveRowsArray = this.getInactiveRowsLocalStorageArray()
      if (inactiveRowsArray) {
        window.localStorage.setItem(
          this.flowInactiveRowsLocalStorageKey,
          inactiveRowsArray.filter((id)=> id !== rowId).toString()
        )
      } else {
        this.createFlowInactiveRowsLocalStorageObject('')
      }
    },
    onRowClose(event, accordionName: string) {
      let rowId
      if (accordionName == 'subscriptions') {
        rowId = 'subscriptions'
      } else if (accordionName == 'initiatives') {
        rowId = Channel.CHANNEL_NAME_INITIATIVES.toLowerCase()
      } else {
        rowId = this.allChannels[event.index].channel.id.intID.toString()
      }

      const inactiveRowsArray = this.getInactiveRowsLocalStorageArray()
      if (inactiveRowsArray) {
        inactiveRowsArray.push(rowId)
        window.localStorage.setItem(
          this.flowInactiveRowsLocalStorageKey,
          inactiveRowsArray.toString()
        )
      } else {
        this.createFlowInactiveRowsLocalStorageObject(rowId)
      }
    },
    createFlowInactiveRowsLocalStorageObject(value: string) {
      window.localStorage.setItem(this.flowInactiveRowsLocalStorageKey, value)
    },
    getInactiveRowsLocalStorageArray(): string[] | null {
      const localStorageObject = window.localStorage.getItem(
        this.flowInactiveRowsLocalStorageKey
      )
      return localStorageObject ? localStorageObject.split(',') : null
    },
    showTacticSummaryOverlay(info){
      // Show tactic summary overlay with delay on first hover, no delay on subsequent hovers
      const tacticSummaryDisplayDelay = this.isDisplayingSummaryTactic ? 0 : 2000
      this.currentSummaryTacticId = info.tactic.id
      if(info.tactic.originalTacticID){
        this.currentSummaryTacticId = info.tactic.originalTacticID
      }
      window.clearTimeout(this.currentSummaryTimeout)
      this.currentSummaryTimeout = window.setTimeout(()=>{
        this.isDisplayingSummaryTactic = true
        // @ts-expect-error
        this.$refs.tacticSummaryOverlay.show({...info.event, currentTarget: info.event.target})
      }, tacticSummaryDisplayDelay)
    },
    hideTacticSummaryOverlay(){
      // Hide tactic details overlay
      // @ts-expect-error
      this.$refs.tacticSummaryOverlay.hide()

      // Reset timeout after delay
      window.clearTimeout(this.currentSummaryTimeout)
      this.currentSummaryTimeout = window.setTimeout(()=>{
        this.isDisplayingSummaryTactic = false
        this.currentSummaryTacticId = {} as ID
        window.clearTimeout(this.currentSummaryTimeout)
      }, 3000)
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

$media-column-width: 20rem;
$expenses-column-width: 20rem;
// Media section hidden for MVP
// $channel-meta-columns-width: #{$media-column-width + $expenses-column-width};
$channel-meta-columns-width: #{$expenses-column-width};

@-moz-document url-prefix() {
  // Cover for padding in ViewMain - allows sticky positioning in Flow view without revealing content while scrolling
  // This targets only Firefox because it does not support :has() solution below
  .flow-container {
    &:before {
      content: '';
      position: fixed;
      display: block;
      top: 0;
      left: $globalNavWidth;
      bottom: 0;
      width: 1.6rem;
      background-color: #fff;
      z-index: 2;
      transition: left 0.3s;
    }
  }

  .global-nav-collapsed {
    .flow-container {
      &:before {
        left: $navCollapseButtonWidth;
      }
    }
  }
}

.view-main:has(.flow-container) {
  padding-left: 0;
  margin-left: 15px;
  width: calc(85% - 15px);
}
.global-nav-collapsed .view-main:has(.flow-container) {
  left: $navCollapseButtonWidth;
  width: calc(100% - $navCollapseButtonWidth - 15px);
}

.flow-container {
  &.show-budget {
    width: calc(100% - #{$expenses-column-width});

    .flow-accordion {
      .p-accordion-header {
        width: calc(100% + #{$channel-meta-columns-width});

        &:after {
          // Line to match border in accordion contents
          right: 20rem;
        }
      }
      .p-toggleable-content {
        clip-path: polygon(
          0% 0%,
          calc(100% + #{$channel-meta-columns-width}) 0%,
          calc(100% + #{$channel-meta-columns-width}) 100%,
          0% 100%
        );
      }
    }
  }
  &:not(.show-budget) {
    .channel-meta-header-container {
      width: 2.4rem;
      height: 0.1rem;
      background: transparent !important;
    }
  }
}

//Accordion overrides
.flow-accordion {
  position: relative;

  .p-accordion-tab {
    margin-bottom: 0;
  }
  .p-accordion-header {
    position: relative;
    width: 100%;

    &:after {
      // Line to match border in accordion contents
      content: "";
      display: block;
      width: 0.1rem;
      height: 3rem;
      background: #999;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.p-disabled {
      opacity: 1;

      .p-accordion-header-link {
        .p-accordion-toggle-icon {
          display: none;
        }
        h2 {
          left: 0.8rem;
        }
      }
    }
    .p-accordion-header-link,
    &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    &:not(.p-disabled).p-highlight .p-accordion-header-link {
      background: #f6f6f6;
      border: none;
      color: $black;
      z-index: 2;
      position: static;
      padding: 0 0 0 0.5rem;
      height: 3rem;

      &:hover {
        background: #f6f6f6;
        border: none;
        color: $black;
      }
    }
    h2,
    .p-accordion-header-text,
    .p-accordion-toggle-icon {
      position: sticky;
      left: 2.4rem;
      margin-top: 0;
    }
    h2 {
      margin: 0;
      font-size: 1.1rem;
      color: #333333;
      @include font-normal;

      .name {
        display: inline-block;
      }

      &:before {
        // Line to match border in accordion contents
        content: "";
        display: block;
        width: 0.1rem;
        height: 3rem;
        background: #999;
        position: absolute;
        top: -0.8rem;
        left: 15.5rem;
      }
    }
    .p-accordion-header-text {
      left: 2.5rem;
    }
    .p-accordion-toggle-icon {
      left: 0.8rem;
    }
  }
  .p-accordion-content {
    padding: 0;
    border: none;
  }
  .p-toggleable-content {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  //AccordionTab no longer supports adding classes after updating to primevue 2.3.0+
  // .channel-accordion-tab {
  //   .monthly-focus {
  //     display: inline-block;
  //     margin-left: 1rem;
  //     font-size: 1rem;
  //   }
  // }
  .events-container {
    border-right: 0.1rem solid #999999;
  }
}

//Flow rows
.flow-dates-row,
.flow-events-row {
  & > .title {
    min-width: 18rem;
    min-height: 2rem;
    margin-right: 1rem;
  }
}
.flow-dates-row {
  position: sticky !important;
  top: 0;
  background-color: #efefef;
  z-index: 1;

  .title {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: #efefef;
  }
  .cell {
    position: relative;

    .month,
    .year,
    .date {
      font-size: 1rem;
      line-height: 1.2rem;
      color: #707070;
      text-transform: uppercase;
    }
    .month-year {
      position: absolute;
      top: 0.2rem;
      left: 0.4rem;
    }
    .year {
      padding-left: 0.25rem;
    }
    .dates {
      display: block;
    }
    .date {
      padding: 1.6rem 0.4rem 0.4rem;
      border-left: 0.1rem solid #999999;
      color: #333333;

      &.starts-mid-week {
        border-left: 0;
      }
    }
  }
}
.flow-events-row {
  min-height: 2.8rem;

  & > .title {
    position: sticky;
    left: 0;
    z-index: 2;

    h2,
    h3 {
      position: absolute;
      height: 100%;
      min-width: inherit;
      max-width: 12rem;
      z-index: 1;
      margin: 0;
      padding: 0.5rem 0 0.5rem 2.5rem;
      border-right: 0.1rem solid #999999;
      background: #fff;
      @include font-normal;
      font-size: 1.1rem;
      color: #333333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;
      pointer-events: none;
    }
  }
  .events-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
}

//Columns for media/expenses
.flow-container {
  .p-accordion-content,
  .flow-events-row,
  .flow-dates-row {
    position: relative;
  }
  .p-accordion-content .channel-meta-body-container,
  .flow-events-row .type-meta-body-container,
  .flow-dates-row .channel-meta-header-container {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
  }
  .flow-dates-row .channel-meta-header-container {
    background-color: #efefef;
    color: #707070;

    h3,
    .expenses-sub-column {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    h3 {
      width: 100%;
      margin-bottom: 0;
      color: #707070;
    }
    .expenses-column {
      margin-right: 0;

      &:before {
        content: "";
        display: block;
        width: 0.1rem;
        height: 3.2rem;
        background: #999;
        position: absolute;
        left: -0.1rem;
        top: 0;
      }
    }
    .expenses-sub-column {
      color: #333333;
    }
  }
  .media-column,
  .expenses-column {
    display: flex;
    flex-wrap: wrap;
  }
  .media-column {
    width: $media-column-width;

    .media-sub-column {
      width: 50%;
    }
  }
  .expenses-column {
    width: $expenses-column-width;
    padding: 0.4rem;
    margin-right: 2.4rem;

    .expenses-sub-column {
      width: 33%;
    }
  }
}

//Expenses and Budget rows
.expenses-budget-row {
  position: relative;
  background-color: #efefef;

  .expense-rows-container {
    display: block;
  }
  .flow-events-row {
    min-height: auto;

    &:first-child {
      .title {
        h2 {
          padding-top: 0.5rem;
        }
      }
    }
    &:last-child {
      .title h3,
      .total-row-value {
        padding-bottom: 1.6rem;
      }
    }
    &.budget-flow-row {
      // border-right: 0.1rem solid #999999;

      h2,
      h3 {
        // opacity: 0;
      }
    }
    & > .title {
      min-height: auto;

      h2,
      h3 {
        position: relative;
        font-size: 1rem;
        line-height: 2rem;
        background-color: #efefef;
        padding: 0 0.4rem 0 2.5rem;
      }
      h2 {
        @include font-bold;
        font-size: 1.1rem;
        color: #333333;
      }
      h3 {
        color: #707070;
      }
    }

    .events-container {
      display: flex;
    }
    .total-row-value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      padding: 0 0.4rem;
      font-size: 1rem;
      line-height: 2rem;
      border-right: 0.1rem solid #999999;
      color: #333333;
    }
  }
  .expenses-column {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: $expenses-column-width;
    padding: 0;
    background-color: #efefef;

    .expenses-section,
    .budget-section {
      width: 100%;

      & > div {
        width: 100%;
      }
    }
    .expenses-section {
      h2 {
        padding-top: 0.5rem;
      }
    }
    .budget-section {
      padding-bottom: 1.6rem;
    }
    h2,
    h3 {
      padding: 0 0.4rem;
      margin: 0;
      font-size: 1rem;
      line-height: 2rem;
    }
    h2 {
      font-size: 1.1rem;
      @include font-bold;
      color: #333333;
    }
    h3 {
      display: flex;
      justify-content: space-between;
      color: #707070;
    }
  }
}
</style>
